import { Avatar, Button, Card, Chip, CircularProgress, Typography } from '@mui/joy';
import { Dialog, DialogTitle, Divider, Grid, IconButton, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import * as React from 'react';
import SearchPIcon from '@mui/icons-material/Search';
import { MdDateRange } from 'react-icons/md';
import { getTeamLeaveHistory, getTeamRequestsWithSearch, logout, stringAvatar } from '../../../service/service-call';
import LeaveRequestView from './LeaveRequestView';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


export default function LeaveTeamHistory() {
    const [leaveHistory, setLeaveHistory] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(false);
    const [expanded, setExpanded] = React.useState(-1);
    const [selectedId, setSelectedId] = React.useState("");
    const [isEditLeaveRequestOpen, setIsEditLeaveRequestOpen] = React.useState(false);
    const [sdate, setSDate] = React.useState(null);
    const [edate, setEDate] = React.useState(null);
    const handleExpandClick = (i, courseId) => {
        setExpanded(expanded === i ? -1 : i);
        // setSelectedCourseId(courseId);
        // getCourseRating(courseId);
    };
    React.useEffect(() => {
        getAllLeaveHistory();
    }, []);


    function getAllLeaveHistory() {
        setIsLoading(true);
        getTeamLeaveHistory().then(resp => {
            setIsLoading(false);
            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {
                setLeaveHistory(data);

            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }



    const handleEDateChange = (e) => {
        setEDate(moment(e).format("YYYY-MM-DD"))
    }

    const handleSDateChange = (e) => {
        setSDate(moment(e).format("YYYY-MM-DD"))
    }

    function toggleViewLeaveRequestModal() {
        setIsEditLeaveRequestOpen(!isEditLeaveRequestOpen);
        if (isEditLeaveRequestOpen === true) {
            getTeamLeaveHistory();
        }
    }

    const BootstrapViewDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialog-paper': {
            minWidth: '500px !important',
            height: 'auto'
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        }
    }));
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialog-paper': {
            minWidth: '400px !important',
            height: 'auto'
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;
        return (
            <DialogTitle sx={{
                alignItems: "center",
                justifyContent: "center",
                alignContent: "space-between"
            }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                    >
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    function searchLeaveRequests() {
        setIsLoading(true);

        getTeamRequestsWithSearch(sdate, edate).then(resp => {
            setIsLoading(false);
            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {
                setLeaveHistory(data);

            });
        }).catch(error => {
            console.log("login user err " + error);
        });

    }
    return (
        <>
            <Stack direction={"row"}>
                <LocalizationProvider dateAdapter={AdapterDateFns} >
                    <DesktopDatePicker

                        label="Start date"

                        value={moment(sdate).format("YYYY-MM-DD")}
                        //disableFuture="true"
                        onChange={handleSDateChange}
                        minutesStep={30}
                        slotProps={{ field: { clearable: true } }}
                        renderInput={(params) => <TextField {...params}
                            sx={{ width: '160px' }}
                            size='small'
                            InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
                            InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}

                        //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                        />}
                    /></LocalizationProvider>&nbsp;&nbsp;&nbsp;

                <LocalizationProvider dateAdapter={AdapterDateFns} >
                    <DesktopDatePicker

                        label="End date"

                        minDate={sdate}
                        value={moment(edate).format("YYYY-MM-DD")}
                        //disableFuture="true"
                        onChange={handleEDateChange}
                        minutesStep={30}
                        slotProps={{ field: { clearable: true } }}
                        renderInput={(params) => <TextField {...params}
                            sx={{ width: '160px' }}
                            size='small'
                            InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
                            InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}

                        //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                        />}
                    /></LocalizationProvider>&nbsp;&nbsp;

                <Button onClick={searchLeaveRequests} variant="soft" style={{ float: 'right', color: '#262673', fontWeight: '700', fontSize: '0.7rem' }} ><SearchPIcon size={"20px"} /></Button>

            </Stack>
            {
                isLoading ? (
                    <div
                        style={{
                            // do your styles depending on your needs.
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {
                            <CircularProgress />
                        }
                    </div>
                ) : ""
            }
            <Grid container direction="row" rowSpacing={2} spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ marginTop: '5px' }}
                style={{
                    minHeight: '440px', maxHeight: '440px',
                    overflowY: 'auto'
                }}>

                {
                    leaveHistory.length > 0 && leaveHistory.map((each, index) => (
                        <Grid item md={12} key={index} margin={"20px"}>

                            <Divider orientation='horizontal' sx={{ fontWeight: '700', fontSize: '1rem' }}>{each.date}</Divider>
                            <br></br>
                            {
                                each.teamHistoryDetails && each.teamHistoryDetails.length === 0 ?
                                    (
                                        <p style={{ color: 'red', textAlign: 'center', padding: '20px' }}>No records found!</p>
                                    ) : ""
                            }
                            {
                                each.teamHistoryDetails && each.teamHistoryDetails.map((e, index) => (
                                    <>
                                        <Card sx={{ backgroundColor: 'white', marginBottom: '10px' }} >

                                            <Stack direction={"row"} spacing={1}>
                                                <Avatar color='success' variant='solid' size="md" {...stringAvatar(e.member.firstName + " " + e.member.lastName)} />

                                                <Stack direction={"column"} style={{ width: '100%' }} >
                                                    <Typography level={"title-md"} sx={{ fontSize: '0.8rem' }}>                {e.member.firstName + " " + e.member.lastName + " (" + e.member.id + ")"}</Typography>
                                                    {
                                                        e.reason ?
                                                            <Stack direction={"row"}>
                                                                <Typography level={"body-xs"} fontWeight={"400"} >{e.reason}</Typography>

                                                            </Stack> : ""
                                                    }

                                                </Stack>
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <div>
                                                    {
                                                        e.startTime && e.endTime ?
                                                            (<>
                                                                <Chip
                                                                    size="md"
                                                                    variant="solid"
                                                                    sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: '#262673', color: 'white' }}
                                                                    startDecorator={<MdDateRange />}>{moment(e.startTime, "hh:mm:ss").format("hh:mm A")}-{moment(e.endTime, "hh:mm:ss").format("hh:mm A")}</Chip>
                                                                &nbsp;
                                                            </>
                                                            ) : ""
                                                    }

                                                    {
                                                        e.leaveType ?
                                                            (
                                                                <Chip variant='outlined' color='danger' size="md"
                                                                    sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                                                                >{e.leaveType && e.leaveType.toLowerCase().replace(/_/g, ' ')}</Chip>
                                                            ) : ""
                                                    }

                                                    &nbsp;


                                                    {
                                                        e.leaveDayType ?
                                                            (
                                                                <>
                                                                    &nbsp; <Chip variant='outlined' color='danger' size="md"
                                                                        sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                                                                    >{e.leaveDayType.toLowerCase().replace(/_/g, ' ')}</Chip>
                                                                </>
                                                            ) : ""
                                                    }

                                                    {
                                                        e.leaveHalfDayType ?
                                                            (
                                                                <>
                                                                    &nbsp; <Chip variant='outlined' color='danger' size="md"
                                                                        sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                                                                    >{e.leaveHalfDayType.toLowerCase().replace(/_/g, ' ')}</Chip>
                                                                </>
                                                            ) : ""
                                                    }



                                                </div>
                                            </Stack>
                                        </Card>
                                    </>
                                ))
                            }

                        </Grid>
                    ))
                }
            </Grid>
            <BootstrapDialog
                onClose={toggleViewLeaveRequestModal}
                aria-labelledby="customized-dialog-title"
                open={isEditLeaveRequestOpen}
            >
                <LeaveRequestView toggleViewLeaveRequest={toggleViewLeaveRequestModal} id={selectedId} />

            </BootstrapDialog>
        </>
    )
}