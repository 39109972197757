import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, Button, Card, CardActions, Chip, CircularProgress, Typography } from '@mui/joy';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Autocomplete, Collapse, Dialog, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import * as React from 'react';
import { BsCheckCircleFill } from "react-icons/bs";
import { FaSearch, FaUserClock, FaUserTie } from 'react-icons/fa';
import SearchPIcon from '@mui/icons-material/Search';
import { ImCancelCircle } from "react-icons/im";
import { IoIosCreate } from 'react-icons/io';
import { MdDateRange } from 'react-icons/md';
import { toast } from 'react-toastify';
import { deleteLeaveRequestById, getAllLeaveType, getMyLeaveRequests, getMyLeaveRequestsWithSearch, logout, stringAvatar } from '../../../service/service-call';
import LeaveRequestEdit from './LeaveRequestEdit';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function MyLeaveRequests() {
  const [searchText, setSearchText] = React.useState("");
  const [allLeaveTypes, setAllLeaveTypes] = React.useState([]);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = React.useState("");
  const [selectedLeaveType, setSelectedLeaveType] = React.useState("");
  const [allLeaveRequests, setAllLeaveRequests] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(-1);
  const [selectedId, setSelectedId] = React.useState("");
  const [reqStatus, setReqStatus] = React.useState("");
  const [isAddTeamOpen, setIsAddTeamOpen] = React.useState(false);
  const [isEditLeaveRequestOpen, setIsEditLeaveRequestOpen] = React.useState(false);
  const [sdate, setSDate] = React.useState(null);
  const [edate, setEDate] = React.useState(null);
  const handleExpandClick = (i, courseId) => {
    setExpanded(expanded === i ? -1 : i);
    // setSelectedCourseId(courseId);
    // getCourseRating(courseId);
  };
  React.useEffect(() => {
    getAllLeaveTypes();
    getAllLeaveRequests(searchText);
  }, []);


  function getAllLeaveTypes() {
    getAllLeaveType("").then(resp => {
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        let arr = data.filter(function (item) {
          return item.name !== "GOVERNMENT_LEAVE"
        })
        arr.unshift({name:'ALL', id:0});
        setAllLeaveTypes(arr);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  function getAllLeaveRequests(value) {
    setIsLoading(true);
    getMyLeaveRequests(value).then(resp => {
      setIsLoading(false);
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        setAllLeaveRequests(data);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  const handleEDateChange = (e) => {
    setEDate(moment(e).format("YYYY-MM-DD"))
  }

  const handleSDateChange = (e) => {
    setSDate(moment(e).format("YYYY-MM-DD"))
  }

  const handleReqStatusChange = (e) => {
    setReqStatus(e.target.value);
  }

  let timelineDataArray = {

    REQUESTED: {
      icon: <IoIosCreate size={"20px"} />,
    },
    REJECTED: {
      icon: <ImCancelCircle size={"20px"} />,
    },
    APPROVED: {
      icon: <BsCheckCircleFill size={"20px"} />,
    },
    PENDING_ADMIN: {
      icon: <FaUserClock size={"20px"} />,
    },
    PENDING_SUPER_ADMIN: {
      icon: <FaUserTie size={"20px"} />,
    }
  };

  function toggleAddTeamModal() {
    setIsAddTeamOpen(!isAddTeamOpen);
    if (isAddTeamOpen === true) {
      getAllLeaveRequests(searchText);
    }
  }

  function toggleEditLeaveRequestModal() {
    setIsEditLeaveRequestOpen(!isEditLeaveRequestOpen);
    if (isEditLeaveRequestOpen === true) {
      getAllLeaveRequests(searchText);
    }
  }

  function addNewLeaveRequests() {
    toggleAddTeamModal();
  }

  function editLeaveRequest(id) {
    setSelectedId(id);
    toggleEditLeaveRequestModal();
  }

  function deleteLeaveRequest(id) {
    deleteLeaveRequestById(id).then(resp => {
      toast.success('Leave Request deleted successfully');
      getAllLeaveRequests(searchText);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast.error(error.response.data.errors[0]);
      } else {
        toast.error("Internal server error, contact support team");
      }
    })
  }

  const BootstrapViewDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '500px !important',
      height: 'auto'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    }
  }));
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '400px !important',
      maxWidth: '500px !important',
      height: 'auto'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{
        alignItems: "center",
        justifyContent: "center",
        alignContent: "space-between"
      }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  function searchLeaveRequests() {
    setIsLoading(true);

    getMyLeaveRequestsWithSearch(sdate, edate, selectedLeaveTypeId===0?null:selectedLeaveTypeId, reqStatus==="ALL"?null:reqStatus).then(resp => {
      setIsLoading(false);
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        setAllLeaveRequests(data);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });

  }

  const CustomPaper = (props) => {
    return <Paper elevation={8} sx={{ fontSize: '0.8rem !important' }} {...props} />;
};
  return (
    <>
      <Stack direction={"row"}>
        <LocalizationProvider dateAdapter={AdapterDateFns} >
          <DesktopDatePicker

            label="Start date"

            value={moment(sdate).format("YYYY-MM-DD")}
            //disableFuture="true"
            onChange={handleSDateChange}
            minutesStep={30}
            slotProps={{ field: { clearable: true } }}
            renderInput={(params) => <TextField {...params}
              sx={{ width: '160px' }}
              size='small'
              InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                    
            //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
            />}
          /></LocalizationProvider>&nbsp;&nbsp;&nbsp;

        <LocalizationProvider dateAdapter={AdapterDateFns} >
          <DesktopDatePicker

            label="End date"

            minDate={sdate}
            value={moment(edate).format("YYYY-MM-DD")}
            //disableFuture="true"
            onChange={handleEDateChange}
            minutesStep={30}
            slotProps={{ field: { clearable: true } }}
            renderInput={(params) => <TextField {...params}
              sx={{ width: '160px' }}
              size='small'
              InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                   
            //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
            />}
          /></LocalizationProvider>&nbsp;
        <Autocomplete
          id="tags-filled"
          sx={{ width: '25%' }}
          options={allLeaveTypes.map(option => option.name)}
          value={selectedLeaveType}
          disableClearable='true'
          PaperComponent={CustomPaper}
          onChange={(event, newValue) => {
            setSelectedLeaveType(newValue);
            let type = allLeaveTypes.find(e => e.name === newValue)
            setSelectedLeaveTypeId(type.id);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size='small'
              label="Select Leave Type"
              style={{ width: '100%' }}
              InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                    
            />
          )}
        />
        &nbsp;&nbsp;
        <FormControl >
          <InputLabel id="demo-simple-select-label" sx={{fontSize: "0.8rem"}} fullWidth size='small'>Request Status</InputLabel>
          <Select
            variant="outlined"
            sx={{ width: '170px', fontSize: "0.8rem" }}
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={reqStatus}
            label="Request Status"
            size='small'
            InputProps={{ style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ style: { fontSize: "0.8rem" } }}
                                   
            onChange={handleReqStatusChange}
          >
            <MenuItem sx={{fontSize:'0.8rem'}} value={"ALL"}>ALL</MenuItem>
            <MenuItem sx={{fontSize:'0.8rem'}} value={"REQUESTED"}>REQUESTED</MenuItem>
            <MenuItem sx={{fontSize:'0.8rem'}} value={"APPROVED"}>APPROVED</MenuItem>
            <MenuItem sx={{fontSize:'0.8rem'}} value={"PENDING_ADMIN"}>PENDING WITH ADMIN</MenuItem>
            <MenuItem sx={{fontSize:'0.8rem'}} value={"PENDING_SUPER_ADMIN"}>PENDING WITH SUPER ADMIN</MenuItem>
            <MenuItem sx={{fontSize:'0.8rem'}} value={"CANCELLED"}>CANCELLED</MenuItem>
            <MenuItem sx={{fontSize:'0.8rem'}} value={"REJECTED"}>REJECTED</MenuItem>
          </Select>
        </FormControl>
        &nbsp;&nbsp;
        <Button onClick={searchLeaveRequests} variant="soft" style={{ float: 'right', color: '#262673', fontWeight: '700', fontSize: '0.7rem' }} ><FaSearch size={"17px"} /> &nbsp;SEARCH</Button>

      </Stack>
      {
        isLoading ? (
          <div
            style={{
              // do your styles depending on your needs.
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {
              <CircularProgress />
            }
          </div>
        ) : ""
      }
      <Grid container direction="row" rowSpacing={2} spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ marginTop: '5px' }}
        style={{
          minHeight: '440px', maxHeight: '440px',
          overflowY: 'auto'
        }}>

        {
          allLeaveRequests.length === 0 ?
            <Grid item md={12}>
              <p style={{ color: 'red' }}>No records found!</p>
            </Grid>
            : ""
        }
        {
          allLeaveRequests.length > 0 && allLeaveRequests.map((each, index) => (
            <Grid item md={12} key={index}>
              <Card key={index} sx={{ width: '100%', backgroundColor: 'aliceblue' }} tabIndex={index}>


                <Grid item xs={12}>
                  <CardActions>
                    <Grid container>
                      <Grid item md={9}>


                        {
                          each.leaveType && each.leaveType.name === "PERMISSION" ?
                            (
                              <Chip
                                size="md"
                                variant="solid"
                                sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: '#262673', color: 'white' }}
                                startDecorator={<MdDateRange />}>{moment(each.leaveRequestDetails[0].date).format("YYYY-MM-DD")} {moment(each.leaveRequestDetails[0].startTime, "hh:mm:ss").format("hh:mm A")}-{moment(each.leaveRequestDetails[0].endTime, "hh:mm:ss").format("hh:mm A")}</Chip>
                            ) : (
                              <Chip
                                size="md"
                                variant="solid"
                                sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: '#262673', color: 'white' }}
                                startDecorator={<MdDateRange />}>{moment(each.startDate).format("YYYY-MM-DD")} - {moment(each.endDate).format("YYYY-MM-DD")}</Chip>
                            )
                        }

                        &nbsp; <Chip variant='outlined' color='danger' size="md"
                          sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                        >{each.leaveType.name.toLowerCase().replace(/_/g, ' ')}</Chip>

                        {
                          each.leaveDayType ?
                            (
                              <>
                                &nbsp; <Chip variant='outlined' color='danger' size="md"
                                  sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                                >{each.leaveDayType.toLowerCase().replace(/_/g, ' ')}</Chip>
                              </>
                            ) : ""
                        }

                        {
                          each.leaveHalfDayType ?
                            (
                              <>
                                &nbsp; <Chip variant='outlined' color='danger' size="md"
                                  sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                                >{each.leaveHalfDayType.toLowerCase().replace(/_/g, ' ')}</Chip>
                              </>
                            ) : ""
                        }
                        <br></br>
                        <Typography variant="plain" level='title-sm' >
                          {each.reason}

                        </Typography>

                      </Grid>
                      <Grid item md={2} textAlign={"center"} alignSelf={"center"}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', float: "left" }}>
                          <Chip variant='solid' size='sm' sx={{ backgroundColor: (each.status === "APPROVED" ? 'green' : each.status === "REQUESTED" ? 'orange' : each.status === "REJECTED" ? 'red' : '#262673') }}>
                            {each.status}
                          </Chip>
                        </div>
                      </Grid>
                    </Grid>

                    {
                      each.status === "REQUESTED" ?
                        <>
                          <IconButton size='small' onClick={() => editLeaveRequest(each.id)} style={{ backgroundColor: "orange" }}>
                            <EditIcon style={{ fill: "white" }} />
                          </IconButton>
                          <IconButton size='small' onClick={() => deleteLeaveRequest(each.id)} style={{ backgroundColor: "red" }}>
                            <DeleteIcon style={{ fill: "white" }} />
                          </IconButton>
                        </>
                        : ""
                    }


                    <ExpandMore
                      expand={expanded}
                      onClick={() => handleExpandClick(index, each.id)}
                      aria-expanded={expanded === index}
                      aria-label="show more"
                    >
                      <IconButton size='small' style={{ backgroundColor: "#262672" }}>
                        <ExpandMoreIcon style={{ fill: "white" }} />
                      </IconButton>
                    </ExpandMore></CardActions>

                  <br></br>
                </Grid>


                <Collapse in={expanded === index} timeout="auto" unmountOnExit >
                  <Timeline position="right">
                    {
                      each.leaveRequestApprovals && each.leaveRequestApprovals.length > 0 && each.leaveRequestApprovals.map((e, i) => (
                        <>
                          <TimelineItem>
                            <TimelineOppositeContent
                              sx={{ m: 'auto 0' }}
                              align="right"
                              variant="overline"
                              color="text.primary"
                            >{e.changedStatus}

                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineConnector />
                              <TimelineDot color='success'>
                                {timelineDataArray[e.changedStatus].icon}
                              </TimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                              <Card>
                                <Stack direction={"row"} spacing={1}>
                                  <Avatar variant='solid' size='sm' {...stringAvatar(e.member ? (e.member.firstName + " " + e.member.lastName) : "U U")}></Avatar>

                                  <Stack direction={"column"} style={{ width: '100%', marginTop: '3px' }} >
                                    <Typography level={"title-md"}>{e.member ? (e.member.firstName + " " + e.member.lastName) : ""}
                                    </Typography>
                                  </Stack>
                                </Stack>
                                {
                                  e.reason && e.reason !== "-" ?
                                    <Typography level='title-sm'  sx={{ fontSize: '0.8rem' }} fontStyle={"italic"}>{"\"" + e.reason + "\""}</Typography> : ""
                                }
                                {
                                  e.changedTime ?
                                    <Typography level='body-sm'  sx={{ fontSize: '0.8rem' }}>
                                      {moment(e.changedTime).format('llll')}
                                    </Typography>
                                    : ""
                                }
                              </Card>
                            </TimelineContent>
                          </TimelineItem>
                        </>
                      ))
                    }
                  </Timeline>
                </Collapse>

              </Card>
            </Grid>
          ))
        }
      </Grid>

      <BootstrapDialog
        onClose={toggleEditLeaveRequestModal}
        aria-labelledby="customized-dialog-title"
        open={isEditLeaveRequestOpen}
      >
        <LeaveRequestEdit toggleEditLeaveRequest={toggleEditLeaveRequestModal} id={selectedId} />

      </BootstrapDialog>

    </>
  )
}