
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';

import styled from "@emotion/styled";
import { Card, Typography } from "@mui/joy";
import { Box, Dialog } from "@mui/material";
import React, { useRef } from "react";
import { BiSolidBookmarkAlt, BiSolidMessageSquareAdd } from "react-icons/bi";
import { RiChatHistoryFill, RiTaskFill } from 'react-icons/ri';
import { TbMailFilled, TbReport } from "react-icons/tb";
import { toast } from "react-toastify";
import { deleteHolidayByAdmin, getAllHoliday, getHoliday, logout } from "../../../service/service-call";
import ApplyLeave from './ApplyLeave';
import LeaveApprovals from './LeaveApproval';
import LeaveHistory from './LeaveHistory';
import LeaveTeamRequests from './LeaveTeamRequest';
import MyLeaveRequests from './MyLeaveRequests';
import LeaveTeamHistory from './LeaveTeamHistory';
import { ApplyCompOff, CompOff } from './CompOff';
import { FaHandPointRight } from 'react-icons/fa';
import { BsMailbox2 } from 'react-icons/bs';
import { MonthLeave } from './MonthLeave';


export default function Leave() {
  const [isAddHolidayOpen, setIsAddHolidayOpen] = React.useState(false);
  const [allHoliday, setAllHoliday] = React.useState([]);
  const [selectedHoliday, setSelectedHoliday] = React.useState("");
  const [holiday, setHoliday] = React.useState();


  const [anchorHolidayEl, setAnchorHolidayEl] = React.useState(null);
  const openHoliday = Boolean(anchorHolidayEl);
  const idHoliday = openHoliday ? 'simple-popover' : undefined;
  const handleHolidayClick = (event) => {
    getHolidayInfo(event.id);
    setAnchorHolidayEl(true);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleHolidayClose = () => {
    setAnchorHolidayEl(null);
  };

  const calendarRef = useRef();

  React.useEffect(() => {
    getAllHolidays();
  }, []);


  function deleteHoliday() {
    deleteHolidayByAdmin(selectedHoliday).then(resp => {

      toast('Holiday deleted successfully');
      getAllHolidays();
      handleHolidayClose();
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast(error.response.data.errors[0]);
      } else {
        toast("Internal server error, contact support team");
      }

    })
  }

  function getAllHolidays() {
    getAllHoliday(selectedHoliday).then(resp => {

      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {

        setAllHoliday(data);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  function getHolidayInfo(id) {
    getHoliday(id).then(resp => {

      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {

        setHoliday(data);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }



  function toggleAddHolidayModal() {
    setIsAddHolidayOpen(!isAddHolidayOpen);
    if (isAddHolidayOpen === true) {
      getAllHolidays();
    }
  }

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '400px !important',
      height: 'auto'
    },
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  return (
    <div>

      <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 430 }}
      >  <Card sx={{ width: '23%', textAlign: 'start' }}>


          {
            localStorage.getItem("role") === 'ROLE_SUPER_ADMIN' ?
              (
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderColor: 'divider', textAlign: 'left' }}
                  TabIndicatorProps={{ sx: { backgroundColor: "orange", color: 'orange' } }}
                >
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="MY APPROVALS" {...a11yProps(0)} icon={<RiTaskFill size={"20px"} />} iconPosition='start' />
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="TEAM REQUESTS" {...a11yProps(1)} icon={<TbMailFilled size={"20px"} />} iconPosition='start' />
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="TEAM HISTORY" {...a11yProps(2)} icon={<RiChatHistoryFill size={"20px"} />} iconPosition='start' />
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="COMP-OFF" {...a11yProps(3)} icon={<BsMailbox2 size={"20px"} />} iconPosition='start' />
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="OVERALL REPORT" {...a11yProps(4)} icon={<TbReport size={"20px"} />} iconPosition='start' />
                </Tabs>

              ) : localStorage.getItem("role") === 'ROLE_ADMIN' ? (
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderColor: 'divider', textAlign: 'left' }}
                  TabIndicatorProps={{ sx: { backgroundColor: "orange", color: 'orange' } }}
                >
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="APPLY LEAVE" {...a11yProps(0)} icon={<BiSolidMessageSquareAdd size={"20px"} />} iconPosition='start' />
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="MY REQUESTS" {...a11yProps(1)} icon={<TbMailFilled size={"20px"} />} iconPosition='start' />

                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="LEAVE HISTORY" {...a11yProps(2)} icon={<BiSolidBookmarkAlt size={"20px"} />} iconPosition='start' />
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="MY APPROVALS" {...a11yProps(3)} icon={<RiTaskFill size={"20px"} />} iconPosition='start' />
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="TEAM HISTORY" {...a11yProps(4)} icon={<RiChatHistoryFill size={"20px"} />} iconPosition='start' />
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="COMP-OFF" {...a11yProps(5)} icon={<BsMailbox2 size={"20px"} />} iconPosition='start' />
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="OVERALL REPORT" {...a11yProps(5)} icon={<TbReport size={"20px"} />} iconPosition='start' />
                </Tabs>
              ) : localStorage.getItem("role") === 'ROLE_USER' ? (
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderColor: 'divider', textAlign: 'left' }}
                  TabIndicatorProps={{ sx: { backgroundColor: "orange", color: 'orange' } }}
                >
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="APPLY LEAVE" {...a11yProps(0)} icon={<BiSolidMessageSquareAdd size={"20px"} />} iconPosition='start' />
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="MY REQUESTS" {...a11yProps(1)} icon={<TbMailFilled size={"20px"} />} iconPosition='start' />


                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="LEAVE HISTORY" {...a11yProps(2)} icon={<BiSolidBookmarkAlt size={"20px"} />} iconPosition='start' />
                  <Tab sx={{ minHeight: '36px', height: '60px' }} style={{ fontSize: '13px', fontWeight: '700', textAlign: 'left', justifyContent: 'flex-start' }} label="COMP-OFF" {...a11yProps(3)} icon={<BsMailbox2 size={"20px"} />} iconPosition='start' />

                </Tabs>
              ) : ""
          }

        </Card>
        <Card sx={{ width: '100%', overflowX: 'auto' }}>

          {
            localStorage.getItem("role") === 'ROLE_ADMIN' || localStorage.getItem("role") === 'ROLE_USER' ? (

              <TabPanel value={value} index={0}>
                <>


                  <ApplyLeave />
                </>
              </TabPanel>
            ) : ""
          }

          {
            localStorage.getItem("role") === 'ROLE_ADMIN' || localStorage.getItem("role") === 'ROLE_USER' ? (
              <TabPanel value={value} index={1}>
                <MyLeaveRequests />
              </TabPanel>
            ) : ""
          }
          {
            localStorage.getItem("role") === 'ROLE_ADMIN' || localStorage.getItem("role") === 'ROLE_USER' ? (

              <TabPanel value={value} index={2}>
                <LeaveHistory />
              </TabPanel>
            ) : ""
          }
          {
            localStorage.getItem("role") === "ROLE_USER" ?
              (<TabPanel value={value} index={3}>
                <CompOff />
              </TabPanel>) : ""
          }
          {
            localStorage.getItem("role") === "ROLE_ADMIN" ?
              (<TabPanel value={value} index={3}>
                <LeaveApprovals />
              </TabPanel>) : ""
          }
          {
            localStorage.getItem("role") === "ROLE_ADMIN" ?
              (<TabPanel value={value} index={4}>
                <LeaveTeamHistory />
              </TabPanel>) : ""
          }
          {
            localStorage.getItem("role") === "ROLE_ADMIN" ?
              (<TabPanel value={value} index={5}>
                <CompOff />
              </TabPanel>) : ""
          }
          {
            localStorage.getItem("role") === "ROLE_ADMIN" ?
              (<TabPanel value={value} index={6}>
                <MonthLeave />
              </TabPanel>) : ""
          }

          {
            localStorage.getItem("role") === "ROLE_SUPER_ADMIN" ?
              (<TabPanel value={value} index={0}>
                <LeaveApprovals />
              </TabPanel>) : ""
          }
          {
            localStorage.getItem("role") === "ROLE_SUPER_ADMIN" ?
              (<TabPanel value={value} index={1}>
                <LeaveTeamRequests />
              </TabPanel>) : ""
          }
          {
            localStorage.getItem("role") === "ROLE_SUPER_ADMIN" ?
              (<TabPanel value={value} index={2}>
                <LeaveTeamHistory />
              </TabPanel>) : ""
          }

          {
            localStorage.getItem("role") === "ROLE_SUPER_ADMIN" ?
              (<TabPanel value={value} index={3}>
                <CompOff />
              </TabPanel>) : ""
          }

            {
            localStorage.getItem("role") === "ROLE_SUPER_ADMIN" ?
              (<TabPanel value={value} index={4}>
                <MonthLeave />
              </TabPanel>) : ""
          }

        </Card>
      </Box>

    </div>
  )
}