import styled from "@emotion/styled";
import CachedIcon from '@mui/icons-material/Cached';
import DownloadIcon from '@mui/icons-material/DownloadForOffline';
import PrintIcon from '@mui/icons-material/LocalPrintshop';
import SearchPIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/Tune';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import SearchIcon from '@mui/icons-material/YoutubeSearchedFor';
import { Button, Chip, Stack, Tooltip } from "@mui/joy";
import { Dialog, IconButton, TextField } from "@mui/material";
import { keyframes } from '@mui/system';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React from "react";
import { toast } from "react-toastify";
import { getAllAttendance, logout } from "../../../service/service-call";
import AttendanceDetails from "./AttendanceDetails";
import AttendanceEdit from "./AttendanceEdit";
function Attendance() {
    const [attendances, setAttendances] = React.useState([]);
    const [selectedAttendanceData, setSelectedAttendanceData] = React.useState("");
    const [selectedAttendance, setSelectedAttendance] = React.useState("");
    const [selectedId, setSelectedId] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [searchLoading, setSearchLoading] = React.useState(false);
    const [sdate, setSDate] = React.useState(moment().format("YYYY-MM-DD"));
    const [edate, setEDate] = React.useState(moment().format("YYYY-MM-DD"));

    React.useEffect(() => {
        getAttendance();
    }, []);

    const handleEDateChange = (e) => {
        setEDate(moment(e).format("YYYY-MM-DD"))
    }

    const handleSDateChange = (e) => {
        setSDate(moment(e).format("YYYY-MM-DD"))
    }

    function reloadAttendance() {
        setSearchLoading(true);
        setEDate(moment().format("YYYY-MM-DD"))
        setSDate(moment().format("YYYY-MM-DD"))
        getAllAttendance(moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")).then(resp => {
            setSearchLoading(false);
            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {
                setAttendances(data);

            });
        }).catch(error => {
            console.log("Your session expired, Please login again", error);
            toast.error(error);
        });
    }
    function getAttendance() {

        if (sdate === "Invalid date" || edate === "Invalid date") {
            toast.error("Invalid search date range");
            return false;
        }
        setSearchLoading(true);
        getAllAttendance(sdate, edate).then(resp => {
            setSearchLoading(false);
            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {
                setAttendances(data);

            });
        }).catch(error => {
            console.log("Your session expired, Please login again", error);
            toast.error(error);
        });
    }
    const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
    function toggleViewTaskModal() {
        setOpen(!open);
    }
    function toggleEditTaskModal() {
        setEditOpen(!editOpen);
        if (editOpen === true) {
            getAttendance();
        }
    }
    function toHoursAndMinutes(totalMinutes) {
        var sign = totalMinutes < 0 ? "-" : "";
        totalMinutes = Math.abs(totalMinutes); // Make totalMinutes positive for calculation
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;
        return hours + ":" + (minutes < 10 ? "0" : "") + minutes + " hrs";
        //return `${hours+":"}${remainingMinutes+" hrs" }`;
    }

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialog-paper': {
            minWidth: '1100px !important',
            height: 'auto'
        },
    }));

    const BootstrapDialogEdit = styled(Dialog)(({ theme }) => ({
        '& .MuiDialog-paper': {
            minWidth: '400px !important',
            height: 'auto'
        },
    }));

    const columns = [
        {
            name: "logBookId",
            label: "Id",
            options: {
                display: false,
                filter: false,
                sort: false,
                customHeadRender: (columnMeta, updateDirection) => (
                    <th style={{ cursor: 'pointer', backgroundColor: 'aliceblue', fontSize: '16px', fontWeight: '300px', height: '70px', color: '#262672' }}>
                        Id
                    </th>
                ),
                setCellProps: value => {
                    return {
                        title: value,
                        style: {
                            textAlign: 'center'
                        }
                    }
                },
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                display: true,
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, updateDirection) => (
                    <th style={{ cursor: 'pointer', backgroundColor: 'aliceblue', fontSize: '16px', fontWeight: '300px', height: '70px', color: '#262672', marginLeft: '5px' }}>
                        Name
                    </th>
                ),
                setCellProps: value => {
                    return {
                        title: value,
                        style: {
                            textAlign: 'left'
                        }
                    }
                },
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, updateDirection) => (
                    <th style={{ cursor: 'pointer', backgroundColor: 'aliceblue', fontSize: '16px', fontWeight: '300px', height: '70px', color: '#262672', maxHeight: '40px', minHeight: '40px' }}>
                        Date
                    </th>
                ),
                setCellProps: value => {
                    return {
                        title: value,
                        style: {
                            textAlign: 'center'
                        }
                    }
                },
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Chip
                        size="sm"
                        variant="solid"
                        sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: '#262673', color: 'white' }}

                    >{value}</Chip>
                )
            }
        },
        {
            name: "loginTime",
            label: "Login Time",
            options: {
                display: true,
                filter: false,
                sort: false,
                customHeadRender: (columnMeta, updateDirection) => (
                    <th style={{ cursor: 'pointer', backgroundColor: 'aliceblue', fontSize: '16px', fontWeight: '300px', height: '70px', color: '#262672' }}>
                        Login Time
                    </th>
                ),
                setCellProps: value => {
                    return {
                        title: value,
                        style: {
                            textAlign: 'center'
                        }
                    }
                },
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Chip
                        size="sm"
                        variant="solid"
                        sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: moment(value, "hh:mm:ss a").isAfter(moment("09:00 AM", "hh:mm a")) ? '#262673' : '#262673', color: 'white' }}

                    >{value ? moment(value, 'HH:mm:ss.SSS').format('hh:mm a') : "-"}</Chip>
                )
            }
        },
        {
            name: "logoutTime",
            label: "Logout Time",
            options: {
                display: true,
                filter: false,
                sort: false,
                customHeadRender: (columnMeta, updateDirection) => (
                    <th style={{ cursor: 'pointer', backgroundColor: 'aliceblue', fontSize: '16px', fontWeight: '300px', height: '70px', color: '#262672' }}>
                        Logout Time
                    </th>
                ),
                setCellProps: value => {
                    return {
                        title: value,
                        style: {
                            textAlign: 'center'
                        }
                    }
                },
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Chip
                        size="sm"
                        variant="solid"
                        sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: moment(value, "hh:mm a").isBefore(moment("06:30 PM", "hh:mm a")) ? '#262673' : '#262673', color: 'white' }}

                    >{value ? moment(value, 'HH:mm:ss.SSS').format('hh:mm a') : "-"}</Chip>
                )
            }
        },
        {
            name: "actualSpent",
            label: "Total Hours Spent",
            options: {
                display: true,
                filter: false,
                sort: false,
                customHeadRender: (columnMeta, updateDirection) => (
                    <th style={{ cursor: 'pointer', backgroundColor: 'aliceblue', fontSize: '16px', fontWeight: '300px', height: '70px', color: '#262672' }}>
                        Total Hours Spent
                    </th>
                ),
                setCellProps: value => {
                    return {
                        title: value,
                        style: {
                            textAlign: 'center'
                        }
                    }
                },
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Chip
                        size="sm"
                        variant="solid"
                        sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: moment(value, "HH:mm:ss").isAfter(moment.duration({ hours: 9, minutes: 20 })) ? 'red' : 'green', color: 'white' }}

                    >{value ? moment(value, 'HH:mm:ss').format('HH:mm') : "-"}</Chip>
                )
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                display: true,
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, updateDirection) => (
                    <th style={{ cursor: 'pointer', backgroundColor: 'aliceblue', fontSize: '16px', fontWeight: '300px', height: '70px', color: '#262672' }}>
                        Status
                    </th>
                ),
                setCellProps: value => {
                    return {
                        title: value,
                        style: {
                            textAlign: 'center'
                        }
                    }
                },
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Chip
                        size="sm"
                        variant="solid"
                        sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: value === "OVER_TIME" ? 'green' : value === "UNDER_TIME" ? 'red' : "orange", color: 'white' }}

                    >{value === "OVER_TIME" ? 'OVER TIME' : value === "UNDER_TIME" ? 'UNDER TIME' : "LEAVE"}</Chip>
                )
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                display: true,
                filter: false,
                sort: false,
                customHeadRender: (columnMeta, updateDirection) => (
                    <th style={{ cursor: 'pointer', backgroundColor: 'aliceblue', fontSize: '16px', fontWeight: '300px', height: '70px', color: '#262672' }}>
                        Action
                    </th>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Button size='sm' onClick={() => viewAttendanceRecords(tableMeta.rowIndex)} style={{ fontSize: '0.8rem' }} style={{ fontSize: '0.8rem' }}>VIEW</Button>
                        {
                            localStorage.getItem("role") !== "ROLE_USER" ? (
                                <>
                                    &nbsp;
                                    <Button size='sm' style={{ backgroundColor: 'red', fontSize: '0.8rem' }} onClick={() => editAttendanceRecords(tableMeta.rowIndex)}>EDIT</Button>
                                </>
                            ) : ""
                        }

                    </>
                ),
                setCellProps: value => {
                    return {
                        title: value,
                        style: {
                            textAlign: 'center'
                        }
                    }
                },
            }
        }

    ];

    function viewAttendanceRecords(index) {
        if (attendances && attendances.length > 0) {
            let data = attendances[index];
            setSelectedAttendanceData(data.logBookDetails);
            //setSelectedId(data[0].logBookDetails)
            toggleViewTaskModal();
        }

    }
    function editAttendanceRecords(index) {
        if (attendances && attendances.length > 0) {
            let data = attendances[index];
            if (data.memberId === localStorage.getItem("userId")) {
                toast.error("You can't modify your logout time, please contact Admin");
            } else {
                setSelectedAttendance(data);
                toggleEditTaskModal();
            }

        }
    }


    const components = {
        icons: {
            SearchIcon,
            PrintIcon,
            DownloadIcon,
            ViewColumnIcon,
            FilterIcon,
        }
    };
    const options = {
        filterType: "dropdown",
        filterArrayFullMatch: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        tableBodyMaxHeight: "280px",
        downloadOptions: {
            filename: 'attendance_details.csv',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            }
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            const alteredData = data?.map((row, index) => ({
                index,
                data: row?.data?.map((field, index) => {
                    if (columns[index].customBodyRender) {
                        //return columns[index].customBodyRender(field)
                    }
                    return field
                }),
            }))
            return `${buildHead(columns)}${buildBody(alteredData)}`.trim()
        },
        // rowsPerPage: 4,
        // rowsPerPageOptions: [4,10,40],
        onRowClick: (rowData, rowMeta) => {
            // //setSelectedId(rowData[0].props.children);
            // console.log(rowData);

            // let data = attendances.length > 0 && attendances.filter(item => item.logBookId === rowData[0]);
            // console.log(data);
            // setSelectedId(data[0].logBookDetails)
            // toggleViewTaskModal();
        },
        customToolbar: (displayData) => {
            return (
                <>
                    <div style={{ float: 'left', padding: '1px' }}>
                        <Stack direction={"row"}>
                            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                                <DesktopDatePicker

                                    label="Start date"
                                    fullWidth
                                    value={moment(sdate).format("YYYY-MM-DD")}
                                    disableFuture="true"
                                    onChange={handleSDateChange}
                                    minutesStep={30}
                                    slotProps={{ field: { clearable: true } }}
                                    renderInput={(params) => <TextField {...params}
                                        InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
                                        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                        size='small'
                                    //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                                    />}
                                /></LocalizationProvider>&nbsp;&nbsp;&nbsp;

                            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                                <DesktopDatePicker

                                    label="End date"
                                    fullWidth
                                    minDateTime={sdate}
                                    value={moment(edate).format("YYYY-MM-DD")}
                                    disableFuture="true"
                                    onChange={handleEDateChange}
                                    minutesStep={30}
                                    slotProps={{ field: { clearable: true } }}
                                    renderInput={(params) => <TextField {...params}
                                        InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
                                        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                        size='small'
                                    //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                                    />}
                                /></LocalizationProvider>&nbsp;

                            <Button size="sm" onClick={() => getAttendance()} loading={searchLoading}><SearchPIcon /></Button>
                        </Stack>
                    </div>

                    <IconButton style={{ float: 'right' }} onClick={() => reloadAttendance()}><Tooltip title="Refresh"><CachedIcon color="grey" /></Tooltip></IconButton>
                </>
            )
        }
        //onRowSelectionChange: (data ,allRowsSelected, rowsSelected) => { console.log(data,allRowsSelected, rowsSelected);}
    };

    return (
        <div style={{ height: '400px ', overflowY: 'scroll' }}>
            {
                attendances && attendances.length > 0 ? (
                    <MUIDataTable
                        size="small"
                        title={""}
                        data={attendances}
                        columns={columns}
                        options={options}
                        components={components}
                    />
                ) : <p style={{ color: 'red' }}>No records found</p>
            }


            <BootstrapDialog
                onClose={toggleViewTaskModal}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <AttendanceDetails data={selectedAttendanceData} toggleViewTaskModal={toggleViewTaskModal} />

            </BootstrapDialog>

            <BootstrapDialogEdit
                onClose={toggleEditTaskModal}
                aria-labelledby="customized-dialog-title"
                open={editOpen}
            >
                <AttendanceEdit data={selectedAttendance} toggleEditTaskModal={toggleEditTaskModal} />

            </BootstrapDialogEdit>
        </div>
    );
}

export default Attendance;