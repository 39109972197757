import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, Button, Card, CardActions, Chip, CircularProgress, Table, Typography } from '@mui/joy';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Collapse, Dialog, DialogTitle, Grid, IconButton, Popover, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import * as React from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { FaUserClock, FaUserTie } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import { IoIosCreate } from 'react-icons/io';
import { MdDateRange } from 'react-icons/md';
import { toast } from 'react-toastify';
import { deleteLeaveRequestById, getAllLeaveType, getMyLeaveApprovals, getMyLeaveRequestsWithSearch, logout, stringAvatar, submitLeaveRequestAction } from '../../../service/service-call';
import LeaveRequestAction from './LeaveRequestAction';
import LeaveRequestEdit from './LeaveRequestEdit';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function LeaveApprovals() {
  const [searchText, setSearchText] = React.useState("");
  const [allLeaveTypes, setAllLeaveTypes] = React.useState([]);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = React.useState("");
  const [selectedLeaveType, setSelectedLeaveType] = React.useState("");
  const [allLeaveRequests, setAllLeaveRequests] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(-1);
  const [selectedId, setSelectedId] = React.useState("");
  const [reqStatus, setReqStatus] = React.useState("");
  const [isAddTeamOpen, setIsAddTeamOpen] = React.useState(false);
  const [isEditLeaveRequestOpen, setIsEditLeaveRequestOpen] = React.useState(false);
  const [isLeaveRequestActionOpen, setIsLeaveRequestActionOpen] = React.useState(false);
  const [sdate, setSDate] = React.useState(null);
  const [edate, setEDate] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState("");
  const [selectedApprovalId, setSelectedApprovalId] = React.useState("");
  const handleExpandClick = (i, courseId) => {
    setExpanded(expanded === i ? -1 : i);
    // setSelectedCourseId(courseId);
    // getCourseRating(courseId);
  };
  React.useEffect(() => {
    getAllLeaveTypes();
    getAllLeaveRequests(searchText);
  }, []);


  function getAllLeaveTypes() {
    getAllLeaveType("").then(resp => {
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        let arr = data.filter(function (item) {
          return item.name !== "GOVERNMENT_LEAVE"
        })
        setAllLeaveTypes(arr);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  function getAllLeaveRequests(value) {
    setIsLoading(true);
    getMyLeaveApprovals(value).then(resp => {
      setIsLoading(false);
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        setAllLeaveRequests(data);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  const handleEDateChange = (e) => {
    setEDate(moment(e).format("YYYY-MM-DD"))
  }

  const handleSDateChange = (e) => {
    setSDate(moment(e).format("YYYY-MM-DD"))
  }

  const handleReqStatusChange = (e) => {
    setReqStatus(e.target.value);
  }

  let timelineDataArray = {

    REQUESTED: {
      icon: <IoIosCreate size={"20px"} />,
    },
    REJECTED: {
      icon: <ImCancelCircle size={"20px"} />,
    },
    APPROVED: {
      icon: <BsCheckCircleFill size={"20px"} />,
    },
    PENDING_ADMIN: {
      icon: <FaUserClock size={"20px"} />,
    },
    PENDING_SUPER_ADMIN: {
      icon: <FaUserTie size={"20px"} />,
    }
  };

  function toggleAddTeamModal() {
    setIsAddTeamOpen(!isAddTeamOpen);
    if (isAddTeamOpen === true) {
      getAllLeaveRequests(searchText);
    }
  }

  function toggleEditLeaveRequestModal() {
    setIsEditLeaveRequestOpen(!isEditLeaveRequestOpen);
    if (isEditLeaveRequestOpen === true) {
      getAllLeaveRequests(searchText);
    }
  }

  function toggleLeaveRequestActionModal() {
    setIsLeaveRequestActionOpen(!isLeaveRequestActionOpen);
    if (isLeaveRequestActionOpen === true) {
      setExpanded(null);
      getAllLeaveRequests(searchText);
    }
  }

  function addNewLeaveRequests() {
    toggleAddTeamModal();
  }

  function editLeaveRequest(id) {
    setSelectedId(id);
    toggleEditLeaveRequestModal();
  }

  function deleteLeaveRequest(id) {
    deleteLeaveRequestById(id).then(resp => {
      toast.success('Leave Request deleted successfully');
      getAllLeaveRequests(searchText);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast.error(error.response.data.errors[0]);
      } else {
        toast.error("Internal server error, contact support team");
      }
    })
  }

  const BootstrapViewDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '500px !important',
      height: 'auto'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    }
  }));
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '400px !important',
      height: 'auto'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{
        alignItems: "center",
        justifyContent: "center",
        alignContent: "space-between"
      }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  function leaveRequestActionClick(status, id) {
    setSelectedStatus(status);
    setSelectedApprovalId(id);
    toggleLeaveRequestActionModal();
  }

  function searchLeaveRequests() {
    setIsLoading(true);

    getMyLeaveRequestsWithSearch(sdate, edate, selectedLeaveTypeId, reqStatus).then(resp => {
      setIsLoading(false);
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        setAllLeaveRequests(data);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });

  }

  const approveLeaveRequestActionClick = (approvalId) => {

    submitLeaveRequestAction(approvalId, "APPROVED", null).then(resp => {
      setExpanded(null);
      toast('Leave request approved successfully');
      getAllLeaveRequests(searchText);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast(error.response.data.errors[0]);
      } else {
        toast("Internal server error, contact support team");
      }

    })

  }

  const [open, setOpen] = React.useState(false);
  const [anchorHolidayEl, setAnchorHolidayEl] = React.useState(null);
  const openHoliday = Boolean(anchorHolidayEl);
  const idHoliday = openHoliday ? 'simple-popover' : undefined;
  const toggleDrawer = () => (event) => {
    setAnchorHolidayEl(true);
  };

  const handleHolidayClose = () => {
    setAnchorHolidayEl(null);
  };

  return (
    <>

      {
        isLoading ? (
          <div
            style={{
              // do your styles depending on your needs.
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {
              <CircularProgress />
            }
          </div>
        ) : ""
      }
      <Grid container direction="row" rowSpacing={2} spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ marginTop: '5px' }}
        style={{
          minHeight: '440px', maxHeight: '440px',
          overflowY: 'auto'
        }}>

        {
          allLeaveRequests.length === 0 ?
            <Grid item md={12}>
              <p style={{ color: 'red' }}>No records found!</p>
            </Grid>
            : ""
        }
        {
          allLeaveRequests.length > 0 && allLeaveRequests.map((each, index) => (
            <Grid item md={12} key={index}>
              <Card key={index} sx={{ width: '100%', backgroundColor: 'aliceblue' }} tabIndex={index}>


                <Grid item xs={12}>
                  <CardActions>
                    <Grid container>
                      <Grid item md={9}>
                        <Stack direction={"row"} spacing={1}>
                          <Avatar color='success' variant='solid' size="md" {...stringAvatar(each.member.firstName + " " + each.member.lastName)} />

                          <Stack direction={"column"} style={{ width: '100%' }} >
                            <Typography level={"title-md"}>                {each.member.firstName + " " + each.member.lastName + " (" + each.member.id + ")"}</Typography>
                            <Stack direction={"row"}>
                              <Typography level={"body-xs"} fontWeight={"400"} >{moment(each.createdDate).format('llll')}</Typography>

                            </Stack>
                          </Stack>
                        </Stack>
                        <br></br>
                        {
                          each.leaveType && each.leaveType.name === "PERMISSION" ?
                            (
                              <Chip
                                size="md"
                                variant="solid"
                                sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: '#262673', color: 'white' }}
                                startDecorator={<MdDateRange />}>{moment(each.leaveRequestDetails[0].date).format("YYYY-MM-DD")} {moment(each.leaveRequestDetails[0].startTime, "hh:mm:ss").format("hh:mm A")}-{moment(each.leaveRequestDetails[0].endTime, "hh:mm:ss").format("hh:mm A")}</Chip>
                            ) : (
                              <Chip
                                size="md"
                                variant="solid"
                                sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: '#262673', color: 'white' }}
                                startDecorator={<MdDateRange />}>{moment(each.startDate).format("YYYY-MM-DD")} - {moment(each.endDate).format("YYYY-MM-DD")}</Chip>
                            )
                        }

                        &nbsp; <Chip variant='outlined' color='danger' size="md"
                          sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                        >{each.leaveType.name.toLowerCase().replace(/_/g, ' ')}</Chip>

                        {
                          each.leaveDayType ?
                            (
                              <>
                                &nbsp; <Chip variant='outlined' color='danger' size="md"
                                  sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                                >{each.leaveDayType.toLowerCase().replace(/_/g, ' ')}</Chip>
                              </>
                            ) : ""
                        }

                        {
                          each.leaveHalfDayType ?
                            (
                              <>
                                &nbsp; <Chip variant='outlined' color='danger' size="md"
                                  sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                                >{each.leaveHalfDayType.toLowerCase().replace(/_/g, ' ')}</Chip>
                              </>
                            ) : ""
                        }
                        <br></br>
                        <Typography variant="plain" level='title-sm' >
                          {each.reason}

                        </Typography>

                      </Grid>
                      <Grid item md={2} textAlign={"center"} alignSelf={"center"}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', float: "left" }}>
                          <Chip variant='solid' size='sm' sx={{ backgroundColor: (each.status === "APPROVED" ? 'green' : each.status === "REQUESTED" ? 'orange' : each.status === "REJECTED" ? 'red' : '#262673') }}>
                            {each.status}
                          </Chip>
                        </div>
                      </Grid>
                    </Grid>


                    {
                      each.status === "APPROVED" ? (
                        <>
                          <IconButton size='small' onClick={() => editLeaveRequest(each.id)} style={{ backgroundColor: "orange" }}>
                            <EditIcon style={{ fill: "white" }} />
                          </IconButton>
                        </>
                      ) : ""
                    }


                    <ExpandMore
                      expand={expanded}
                      onClick={() => handleExpandClick(index, each.id)}
                      aria-expanded={expanded === index}
                      aria-label="show more"
                    >
                      <IconButton size='small' style={{ backgroundColor: "#262672" }}>
                        <ExpandMoreIcon style={{ fill: "white" }} />
                      </IconButton>
                    </ExpandMore></CardActions>

                  <br></br>
                </Grid>


                <Collapse in={expanded === index} timeout="auto" unmountOnExit >
                  <Button color="primary" variant='solid' size="small" onClick={toggleDrawer()} style={{ fontSize: '0.9rem', padding: '5px' }}>
                    Check leave history
                  </Button>
                  <Popover
                    id={idHoliday}
                    open={openHoliday}
                    anchorEl={anchorHolidayEl}
                    onClose={handleHolidayClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    style={{}}
                  >
                    <Table variant='soft' sx={{ textAlign: 'left', width: '400px', backgroundColor: 'white', padding: '5px' }} >
                      <thead style={{ border: '1px solid grey' }}>
                        <tr >
                          <td style={{ fontWeight: 'bold', width: '80%' }} >
                            Leave Type
                          </td>
                          <td style={{ fontWeight: 'bold' }}>
                            Value
                          </td>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: '19px', fontWeight: '400' }}>
                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Total Leaves
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.allocatedLeaves}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Used Leaves
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.usedLeaves}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Available Leaves
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.availableLeaves}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Upcoming approved Leaves
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.upcomingApprovedLeaves}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Requested Leaves
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.requestedLeaves}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Available Permission
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.availablePermissionsThisMonth}
                          </td>
                        </tr>


                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Used Permission
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.usedPermissionsThisMonth}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Upcoming approved permission
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.upcomingApprovedPermission}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Requested permission
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.requestedPermission}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Available compensation leaves
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.availableCompensationLeaves}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Used compensation leaves
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.usedCompensationLeaves}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Requested compensation leaves
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.requestedCompensationLeaves}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontSize: '0.8rem' }}>
                            Upcoming approved compensation leaves
                          </td>
                          <td style={{ fontSize: '0.8rem' }}>
                            {each.leaves.upComingApprovedCompensationLeaves}
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                  </Popover>


                  <Timeline position="right">
                    {
                      each.leaveRequestApprovals && each.leaveRequestApprovals.length > 0 && each.leaveRequestApprovals.map((e, i) => (
                        <>
                          <TimelineItem>
                            <TimelineOppositeContent
                              sx={{ m: 'auto 0' }}
                              align="right"
                              variant="overline"
                              color="text.primary"
                            >{e.changedStatus}

                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineConnector />
                              <TimelineDot color='success'>
                                {timelineDataArray[e.changedStatus].icon}
                              </TimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                              <Card>
                                <Stack direction={"row"} spacing={1}>
                                  <Avatar variant='solid' size='sm' {...stringAvatar(e.member ? (e.member.firstName + " " + e.member.lastName) : "U U")}></Avatar>

                                  <Stack direction={"column"} style={{ width: '100%', marginTop: '3px' }} >
                                    <Typography level={"title-md"}>{e.member ? (e.member.firstName + " " + e.member.lastName) : ""}
                                    </Typography>
                                  </Stack>
                                </Stack>
                                {
                                  e.member && e.member.id === localStorage.getItem("userId") ?
                                    (
                                      <>
                                        <Stack direction={"row"}>
                                          <Button sx={{ fontSize: '0.8rem' }} size='sm' variant='solid' color='primary' onClick={() => approveLeaveRequestActionClick(e.id)}>APPROVE</Button>&nbsp;
                                          <Button sx={{ fontSize: '0.8rem' }} variant='solid' color='danger' size='sm' onClick={() => leaveRequestActionClick('REJECT', e.id)}>REJECT</Button>
                                        </Stack>
                                      </>
                                    ) : ""
                                }
                                {
                                  e.reason && e.reason !== "-" ?
                                    <Typography sx={{ fontSize: '0.8rem' }} level='title-sm' fontStyle={"italic"}>{"\"" + e.reason + "\""}</Typography> : ""
                                }
                                {
                                  e.changedTime ?
                                    <Typography level='body-sm' sx={{ fontSize: '0.8rem' }}>
                                      {moment(e.changedTime).format('llll')}
                                    </Typography>
                                    : ""
                                }
                              </Card>
                            </TimelineContent>
                          </TimelineItem>
                        </>
                      ))
                    }
                  </Timeline>
                </Collapse>

              </Card>
            </Grid>
          ))
        }
      </Grid>

      <BootstrapDialog
        onClose={toggleEditLeaveRequestModal}
        aria-labelledby="customized-dialog-title"
        open={isEditLeaveRequestOpen}
      >
        <LeaveRequestEdit toggleEditLeaveRequest={toggleEditLeaveRequestModal} id={selectedId} />

      </BootstrapDialog>

      <BootstrapDialog
        onClose={toggleLeaveRequestActionModal}
        aria-labelledby="customized-dialog-title"
        open={isLeaveRequestActionOpen}
      >
        <LeaveRequestAction toggleLeaveRequestAction={toggleLeaveRequestActionModal} status={selectedStatus} approvalId={selectedApprovalId} />

      </BootstrapDialog>

    </>
  )
}