import { Autocomplete, AvatarGroup, CardMedia, Collapse, Dialog, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField, Tooltip } from '@mui/material';
import * as React from 'react';
import { GrProjects } from "react-icons/gr";
import { CgNotes } from "react-icons/cg";
import { keyframes } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AspectRatio, Avatar, Button, Card, CardActions, CardContent, Chip, CircularProgress, Divider, ListItemDecorator, Typography } from '@mui/joy';
import { styled } from '@mui/material/styles';
import { deleteTrainingById, editNewTraining, getAllActiveMembers, getAllProject, getAllProjectsUnderAdmin, getAllTraining, logout, stringAvatar } from '../../service/service-call';
import moment from 'moment';
import { LuClock } from 'react-icons/lu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { BsCalendar2Event } from 'react-icons/bs';
import AddTrainings from './trainingsAdd';
import EditTrainings from './trainingsEdit';
import { toast } from 'react-toastify';
import { fontSize } from '@xstyled/system';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Add, Search } from '@mui/icons-material';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    marginTop:'-30%',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


export default function Trainings() {
  const [allProjects, setAllProjects] = React.useState([]);
  const [allMembers, setAllMembers] = React.useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = React.useState("");
  const [sdate, setSDate] = React.useState(null);
  const [edate, setEDate] = React.useState(null);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [searchText, setSearchText]= React.useState("");
  const [allTrainings, setAllTrainings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(-1);
  const [selectedId, setSelectedId] = React.useState("");
  const [isAddTeamOpen, setIsAddTeamOpen] = React.useState(false);
  const [isEditTrainingOpen, setIsEditTrainingOpen] = React.useState(false);

  const [selectedOption, setSelectedOption] = React.useState("");
  const handleExpandClick = (i, courseId) => {
    setExpanded(expanded === i ? -1 : i);
    // setSelectedCourseId(courseId);
    // getCourseRating(courseId);
  };

  const handleEDateChange = (e) => {
    if(moment(e).format("YYYY-MM-DD")==="Invalid date"){
      setEDate(null);
      let selectedMember = null;
      if(selectedTeamMembers){
        let idCode = selectedTeamMembers.split("(");
        selectedMember = idCode[1].split(")")[0];
      }
      getAllTrainings(selectedOption, selectedMember, sdate, null);
    } else {
      setEDate(moment(e).format("YYYY-MM-DD"));
      let selectedMember = null;
      if(selectedTeamMembers){
        let idCode = selectedTeamMembers.split("(");
        selectedMember = idCode[1].split(")")[0];
      }
      getAllTrainings(selectedOption, selectedMember, sdate, moment(e).format("YYYY-MM-DD"));
    }
   
    
  }

  const handleSDateChange = (e) => {
    if(moment(e).format("YYYY-MM-DD")==="Invalid date"){
      setSDate(null);
      let selectedMember = null;
      if(selectedTeamMembers){
        let idCode = selectedTeamMembers.split("(");
        selectedMember = idCode[1].split(")")[0];
      }
      getAllTrainings(selectedOption, selectedMember, null, edate);
    } else {
      setSDate(moment(e).format("YYYY-MM-DD"));
      let selectedMember = null;
      if(selectedTeamMembers){
        let idCode = selectedTeamMembers.split("(");
        selectedMember = idCode[1].split(")")[0];
      }
      getAllTrainings(selectedOption, selectedMember, moment(e).format("YYYY-MM-DD"), edate);
    }
    
  }

  React.useEffect(() => {
    let selectedMember = null;
    if(selectedTeamMembers){
      let idCode = selectedTeamMembers.split("(");
      selectedMember = idCode[1].split(")")[0];
    }
    getAllTrainings(selectedOption, selectedMember, sdate, edate);
  }, []);

  React.useEffect(() => {
    getAllMemberForTeam();
    getAllProjects();
}, []);

function getAllProjects() {
    getAllProjectsUnderAdmin("").then(resp => {
        if(resp.status === 401){
            logout();
        }
        resp.json().then(data => {
            setAllProjects(data);

        });
    }).catch(error => {
        console.log("login user err " + error);
    });
}

function getAllMemberForTeam() {
    getAllActiveMembers().then(resp => {
        
        if(resp.status === 401){
            logout();
        }
        resp.json().then(data => {
            
            setAllMembers(data);

        });
    }).catch(error => {
        console.log("login user err " + error);
    });
}

  function getAllTrainings(projId, memId, stDate, enDate){
    setIsLoading(true);
    getAllTraining(projId, memId, stDate, enDate).then(resp => {
      setIsLoading(false);
      if(resp.status === 401){
        logout();
    }
      resp.json().then(data => {
        setAllTrainings(data);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  function toggleAddTeamModal() {
    setIsAddTeamOpen(!isAddTeamOpen);
    if (isAddTeamOpen === true) {
        getAllTrainings(null, null, null, null);
    }
  }

  function toggleEditTrainingModal() {
    setIsEditTrainingOpen(!isEditTrainingOpen);
    if (isEditTrainingOpen === true) {
        getAllTrainings(null,null,null,null);
    }
  }

  function addNewTrainings(){
    toggleAddTeamModal();
  }

  function editTraining(id){
    setSelectedId(id);
    toggleEditTrainingModal();
  }

  function deleteTraining(id){
    deleteTrainingById(id).then(resp => {
      toast.success('Training deleted successfully');
      getAllTrainings(null, null,null,null);
  }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
          toast.error(error.response.data.message);
        } else if(error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0){
          toast.error(error.response.data.errors[0]);
        } else {
          toast.error("Internal server error, contact support team");
        }
    })
  }

  const BootstrapViewDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '500px !important',
      height: 'auto'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    }
  }));
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '400px !important',
      height: 'auto'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{alignItems: "center", 
      justifyContent: "center",
      alignContent: "space-between" }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const CustomPaper = (props) => {
    return <Paper elevation={8} sx={{ fontSize: '0.8rem !important' }} {...props} />;
};
const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
    return (
        <>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
  <FormControl fullWidth variant="outlined" size="small" sx={{ flex: 1 }}>
    <InputLabel id="demo-simple-select-standard-label">Projects</InputLabel>
    <Select
      labelId="demo-select-small"
      id="demo-select-small"
      value={selectedOption}
      label="Projects"
      onChange={(event) => {

        let selectedMember = null;
                    if(selectedTeamMembers){
                      let idCode = selectedTeamMembers.split("(");
                      selectedMember = idCode[1].split(")")[0];
                    }
      console.log(event.target.value, selectedMember, sdate, edate);
     
       getAllTrainings(event.target.value, selectedMember, sdate, edate);
        setSelectedOption(event.target.value);
        getAllMemberForTeam(event.target.value);
      }}
      sx={{ fontSize: '0.9rem' }}
    >
      {allProjects.map((item) => (
        <MenuItem key={item.id} value={item.id} sx={{ fontSize: '0.9rem' }}>
          {item.projectName}
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  <Autocomplete
    id="tags-filled"
    size="small"
    options={allMembers.map((option) => `${option.firstName} ${option.lastName} (${option.id})`)}
    value={selectedTeamMembers}
    onChange={(event, newValue) => {
      let selectedMember = null;
                    if(newValue){
                      let idCode = newValue.split("(");
                      selectedMember = idCode[1].split(")")[0];
                    }
      getAllTrainings(selectedOption, selectedMember, sdate, edate);
      console.log(selectedOption, selectedMember, sdate, edate);
      setSelectedTeamMembers(newValue)
    }}
    PaperComponent={CustomPaper}
    renderTags={(value, getTagProps) =>
      value.map((option, index) => (
        <Chip
          key={option}
          avatar={<Avatar size="lg" color="danger" {...stringAvatar(option)} />}
          variant="outlined"
          label={option}
          {...getTagProps({ index })}
        />
      ))
    }
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        label="Select Trainer"
        placeholder="Select Trainer"
        sx={{
          fontSize: '0.8rem',
          '& .MuiInputLabel-root': { fontSize: '0.8rem' },
          '& .MuiOutlinedInput-root': { fontSize: '0.8rem' },
        }}
      />
    )}
    sx={{ flex: 2 }}
  />

  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
      label="Start date"
      value={sdate}
      onChange={handleSDateChange}
      //disableFuture
      renderInput={(params) => (
        <TextField
          {...params}
          size='small'
          variant="outlined"
          sx={{
            fontSize: '0.8rem',
            '& .MuiInputLabel-root': { fontSize: '0.8rem' },
            '& .MuiOutlinedInput-root': { fontSize: '0.8rem' },
          }}
        />
      )}
      sx={{ flex: 1 }}
    />
  </LocalizationProvider>

  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
      label="End date"
      value={edate}
      onChange={handleEDateChange}
      //disableFuture
      minDate={sdate}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size='small'
          sx={{
            fontSize: '0.8rem',
            '& .MuiInputLabel-root': { fontSize: '0.8rem' },
            '& .MuiOutlinedInput-root': { fontSize: '0.8rem' },
          }}
        />
      )}
      sx={{ flex: 1 }}
    />
  </LocalizationProvider>

  <Button
    onClick={addNewTrainings}
    sx={{ fontWeight: 700, fontSize: '0.8rem' }}
  >
    <Add size="20px" />&nbsp;TRAININGS
  </Button>
</Stack>

        
        
        {
          isLoading ? (
              <div
                    style={{
                      // do your styles depending on your needs.
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    {  
                      <CircularProgress />
                    }
                  </div>
          ):""
        }
        <Grid container direction="row" rowSpacing={2} spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{marginTop:'5px'}} 
        style={{minHeight:'440px', maxHeight:'440px', 
    overflowY: 'auto'}}>

        {
          allTrainings.length > 0 && allTrainings.map((each, index) => (
            <Grid item md={12} key={index}>
                <Card key={index} sx={{width:'100%', backgroundColor:'aliceblue'}} tabIndex={index}>
                  
                  <CardContent style={{ fontFamily: 'Roboto'}}>
                        
                        {/* <CardMedia
                            sx={{ padding: "10px", objectFit: "contain", height:"100px", width:"300px"}}
                            
                                component="img"
                                image={each.courseId.thumbnail}
                                
                            /> */}
                        
                        <Grid item xs={12}>
                            <CardActions>
                                <Grid container>
                                    <Grid item md={12}>
                                        <Typography variant="plain" level='title-lg' >
                                       {each.topic}

                                        </Typography>
                                        <Stack direction={"row"} spacing={3} >
                                        <Tooltip title="Project name" arrow>
                                        <Chip size='sm' color='primary' sx={{fontSize:'0.8rem', borderRadius:'5px'}}>
                                        {each.projectId.projectName}
                                        </Chip>
                                        </Tooltip>
                                        <Tooltip title="Trainer" arrow>
                                        <Chip sx={{fontSize:'0.8rem'}} size='sm' variant='outlined' color='primary'
                                        startDecorator={<Avatar variant='solid' {...stringAvatar(each.trainerId.firstName + " " + each.trainerId.lastName)}></Avatar>}>
                                        {each.trainerId.firstName + " " + each.trainerId.lastName}
                                        </Chip>
                                        
                                        </Tooltip>
                                        <Tooltip title="Scheduled on" arrow>
                                        <Chip size='sm' variant='outlined' 
                                        sx={{fontSize:'0.8rem', borderRadius:'5px', backgroundColor:'#262673', color:'white'}}
                                        startDecorator={<BsCalendar2Event/>}>
                                        {moment(each.startDate).format("YYYY-MM-DD")} {moment(each.startDate).format("hh:mm A")} - {moment(each.endDate).format("hh:mm A")}
                                        </Chip>
                                        </Tooltip>
                                        <Chip variant={(each.status==="INPROGRESS")?'solid':"outlined"} size='sm' color='danger'
                                         sx={{fontSize:'0.8rem', borderRadius:'5px' , animation: (each.status==="INPROGRESS")?`${blink} 0.5s linear infinite`:""}}>
                                        {each.status}
                                        </Chip>
                                        
                                        <>
                            <IconButton onClick={()=>editTraining(each.id)} style={{backgroundColor:  "orange"}}>
                            <EditIcon style={{fill: "white", fontSize:'15px'}}/>
                            </IconButton>
                            <IconButton onClick={()=>deleteTraining(each.id)} style={{backgroundColor:  "orange"}}>
                            <DeleteIcon style={{fill: "white", fontSize:'15px'}}/>
                            </IconButton>
                            
                            </>

                            
                                        </Stack>
                                        <div style={{float:'right'}}>
                                        <ExpandMore 
                        expand={expanded}
                        onClick={()=>handleExpandClick(index, each.id)}
                        aria-expanded={expanded===index}
                        aria-label="show more"
                        
                        >
                            <IconButton  style={{backgroundColor:  "#262672"}}>
                        <ExpandMoreIcon style={{fill: "white", fontSize:'15px'}}/>
                        </IconButton>
                        </ExpandMore></div>
                                        <Divider sx={{marginTop:'10px', marginBottom:'10px'}}/>
                                        <Typography level='body-md' fontWeight={400} sx={{opacity:'0.7'}} >
                                       {each.description}

                                        </Typography>
                                        <br></br>
                                        <ListItemDecorator >
                                        {
        each.memberIds && each.memberIds.length > 10 ? (
          <AvatarGroup>
            <>
            {
                each.memberIds.map((e,ind) => (
                  ind < 10 ? (
<Tooltip  title={e.firstName+" "+e.lastName} >
            <Avatar size="sm" {...stringAvatar(e.firstName+" "+e.lastName)}></Avatar>
                   </Tooltip> 
                ):"")
                  )

            }
          
                  
                   
                   </>
                   <Avatar size="sm">+{each.memberIds.length-10}</Avatar>
       </AvatarGroup>
        ): (
          <AvatarGroup>
          {
             each.memberIds && each.memberIds.length > 0 ? each.memberIds.map((eac, ind) => (
               <>
                   <Tooltip  title={eac.firstName+" "+eac.lastName} ><Avatar size="sm" {...stringAvatar(eac.firstName+" "+eac.lastName)}></Avatar>
                   </Tooltip>
                     
                     </>
             )) :""
          }        
         
         
       </AvatarGroup>
        )
      }           
                                        </ListItemDecorator>
                                    </Grid>
                                </Grid>
                            
                            
                         {/* {
                          localStorage.getItem("userId")===each.trainerId.id ? ( */}
                            
                           {/* ):""
                         } */}
                        
                        
                    </CardActions>
                   
                    <br></br>
                        </Grid>
                  
                    
                    <Collapse in={expanded===index} timeout="auto" unmountOnExit >
                   
                    <Card style={{fontSize:'0.8rem', maxHeight:'250px', width:'40%', overflowY:'auto'}}>
        <Typography level='body-md' variant='solid'>PARTICIPANTS:</Typography>
            <Stack direction={"column"}>
      {
        each.memberIds && each.memberIds.length > 0 ? 
        each.memberIds.map((e, ind) => (
        (
          
           <>
            <Divider sx={{m:'10px'}}/>
           <ListItemDecorator>
              <Avatar size="md" {...stringAvatar(e.firstName+" "+e.lastName)}></Avatar>
             
              <Typography fontWeight="md" class="font-bold" level="body-xs" sx={{color:'#262673', fontSize:'0.7rem'}}>
                &nbsp;{e.firstName.toUpperCase()+" "+e.lastName.toUpperCase()}
              </Typography>
              </ListItemDecorator>
             
              </>
        ))):""
      }
       </Stack>
              </Card>
                     
                    </Collapse>
                  </CardContent>
                  
                </Card>
              </Grid>
          ))
        }
</Grid>

<BootstrapDialog
        onClose={toggleAddTeamModal}
        aria-labelledby="customized-dialog-title"
        open={isAddTeamOpen}
      >
        {/*<BootstrapDialogTitle id="customized-dialog-title" className="toolHeader" style={{ textAlign: 'center', backgroundImage:'url(https://t4.ftcdn.net/jpg/02/76/08/07/360_F_276080724_hltnCyDjcqAyRtLzDYo3T2jXbBtCD7fl.jpg)', color: 'white' }}>
        ADD MEMBER
    </BootstrapDialogTitle>*/}

        <AddTrainings addTeamsModal={toggleAddTeamModal} />

      </BootstrapDialog>

      <BootstrapDialog
        onClose={toggleEditTrainingModal}
        aria-labelledby="customized-dialog-title"
        open={isEditTrainingOpen}
      >

        <EditTrainings editTrainingsModal={toggleEditTrainingModal} id={selectedId} />

      </BootstrapDialog>
        {/* <BootstrapDialog
        onClose={toggleAddProjectModal}
        aria-labelledby="customized-dialog-title"
        open={isAddProjectOpen}
      >

        <AddProjects addProjectsModal={toggleAddProjectModal} />

      </BootstrapDialog> */}
{/* 
      <BootstrapDialog
        onClose={toggleEditProjectModal}
        aria-labelledby="customized-dialog-title"
        open={isEditProjectOpen}
      >

        <EditProjects editProjectModal={toggleEditProjectModal} selectedProjectId={selectedProjectId} />

      </BootstrapDialog>

       */}
      
      </>
    )
  }