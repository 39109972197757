import { Button } from '@mui/joy';
import { Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { toast } from 'react-toastify';
import { submitLeaveRequestAction } from '../../../service/service-call';
//import { addNewMember } from '../util/apiCalls';

export default function LeaveRequestAction({ toggleLeaveRequestAction, status, approvalId }) {
    const [message, setMessage] = React.useState('');
    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const submitLeaveRequestActionClick = () => {
        let value = "";
        if (status === "APPROVE") {
            value = "APPROVED";
        } else if (status === "REJECT") {
            value = "REJECTED";
        }
        if (message === null || message === undefined || message === "") {
            toast('Please give a reason');
            return false;
        }

        submitLeaveRequestAction(approvalId, value, message).then(resp => {

            toast('Leave request ' + value.toLowerCase() + ' successfully');
            toggleLeaveRequestAction();
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast(error.response.data.message);
            } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                toast(error.response.data.errors[0]);
            } else {
                toast("Internal server error, contact support team");
            }

        })

    }


    return (
        <React.Fragment>
            <DialogContent>
                <Grid container >


                    <Box sx={{ flexGrow: 1 }}>


                        <Typography>Your reason:</Typography>
                        <TextField
                            multiline
                            rows={3}
                            type="text"
                            fullWidth
                            inputRef={input => input && input.focus()}
                            variant="outlined"
                            size='small'
                            value={message}

                            inputProps={{
                                maxlength: 200
                            }}
                            helperText={`${message.length}/${200}`}
                            onChange={handleMessageChange}
                        /><br></br><br></br>
                        <Button variant='solid' color={"success"} onClick={submitLeaveRequestActionClick}> {status}</Button>
                        &nbsp;<Button variant='outlined' onClick={() => toggleLeaveRequestAction()}> Close to exit</Button>
                    </Box>





                </Grid>

            </DialogContent>
        </React.Fragment>
    );
}