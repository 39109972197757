import { Box, Button, Divider, Typography } from '@mui/joy';
import { DialogActions, DialogContent, TextField } from '@mui/material';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import * as React from 'react';
import { toast } from 'react-toastify';
import { editAttendance } from '../../../service/service-call';


export default function AttendanceEdit({ data, toggleEditTaskModal }) {
  const [logoutTime, setLogoutTime] = React.useState(null);
  const [message, setMessage] = React.useState('');

  const handleLogoutTimeChange = (e) => {
    setLogoutTime(e)
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const editAttendanceClick = () => {
    if (message === null || message === undefined || message === "") {
      toast('Message cannot be blank!');
      return false;
    }

    if (!logoutTime) {
      toast('Please choose Logout time!');
      return false;
    }

    editAttendance(data.memberId, data.date, moment(logoutTime).format('hh:mm A'), message).then(resp => {
      toast('Logout time updated successfully');
      toggleEditTaskModal();
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast(error.response.data.errors[0]);
      } else {
        toast("Internal server error, contact support team");
      }
    })
  }
  return (
    <>

      <DialogContent>
        <Typography level={"title-lg"} >UPDATE ATTENDANCE</Typography>
        <br></br>
        <Divider />
        <br></br>
        {
          moment(data.date).isSame(moment(), 'day') ? (
            <div className="blinking-text">
              NOTE: Before click SUBMIT, please ensure that the member logs out from his/her machine
            </div>
          ) : ""
        }
        <br></br>
        <Typography level={"title-sm"} sx={{ color: '#262673' }}>For: &nbsp;&nbsp;{data.name + " (" + data.memberId + ")"}</Typography>
        <Typography level={"title-sm"} sx={{ color: '#262673' }}> Date: {data.date}</Typography>
        <Box sx={{ flexGrow: 1, padding: '10px' }}>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
            <DesktopTimePicker

              label="Logout time"
              fullWidth
              value={logoutTime}
              disablePast="true"
              onChange={handleLogoutTimeChange}
              slotProps={{ field: { clearable: true } }}
              renderInput={(params) => <TextField {...params}
                InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
                InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                size='small'
              //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
              />}
            /></LocalizationProvider>

          <br></br><br></br>
          <Typography>Reason:</Typography>
          <TextField
            multiline
            rows={3}
            type="text"
            fullWidth
            inputRef={input => input && input.focus()}
            variant="outlined"
            size='small'
            value={message}

            inputProps={{
              maxlength: 200,
              style: { fontSize: '0.8rem' }
            }}
            helperText={`${message.length}/${200}`}
            onChange={handleMessageChange}
          /><br></br><br></br>



        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={editAttendanceClick} style={{ fontSize: '0.8rem' }}> SUBMIT</Button>
        <Button variant='soft' onClick={toggleEditTaskModal} style={{ fontSize: '0.8rem' }}>&nbsp;CLOSE</Button>
      </DialogActions>
    </>
  );
}