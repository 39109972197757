import { Button } from '@mui/joy';
import { DialogContent } from '@mui/material';
import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
  } from '@mui/material';

export const PendingRatingTasks = ({ togglePendingRatingTasksModal, data }) => {
    

    
  return (
    <>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Typography fontWeight="bold">Task ID</Typography></TableCell>
            <TableCell><Typography fontWeight="bold">Title</Typography></TableCell>
            <TableCell><Typography fontWeight="bold">Assigned To</Typography></TableCell>
            <TableCell><Typography fontWeight="bold">QC Assigned To</Typography></TableCell>
            <TableCell><Typography fontWeight="bold">Completed On</Typography></TableCell>
            <TableCell><Typography fontWeight="bold">Elapsed Days</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((task) => (
            <TableRow key={task.taskId}>
              <TableCell>{task.taskId}</TableCell>
              <TableCell>{task.title}</TableCell>
              <TableCell>{`${task.assignedTo.firstName} ${task.assignedTo.lastName} (${task.assignedTo.role})`}</TableCell>
              <TableCell>{`${task.qcAssignedTo.firstName} ${task.qcAssignedTo.lastName} (${task.qcAssignedTo.role})`}</TableCell>
              <TableCell>{new Date(task.completedOn).toLocaleDateString()}</TableCell>
              <TableCell>{task.elapsedDays}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                                <br></br> &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button variant={"solid"}  style={{float:'right'}} size='sm'>COPY TO CLIPBOARD</Button>
                        &nbsp;&nbsp;
                        <Button variant={"solid"} style={{float:'right'}} color='danger' size='sm'>CLOSE</Button>
    
   
    </>
  )
}
