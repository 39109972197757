import { Card, CardContent, Typography } from '@mui/joy';
import { Chip, Grid } from '@mui/material';
import { easeQuadInOut } from 'd3-ease';
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import CountUp from 'react-countup';
import AnimatedProgressProvider from '../Charts/AnimatedProgressProvider';
import { convertSecondsToHours } from '../../service/service-call';
function ReportCard({ performanceData }) {
    return (
        <Card
            variant="outlined"
            orientation="horizontal"
            
            sx={{
                boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder'
            }}
        >
            <CardContent>
                <Typography level="title-lg" id="card-description" sx={{ fontSize: '0.9rem' }}>
                    {performanceData.name}:
                </Typography>
                <Grid container spacing={1}>
                    <Grid item md={3}>
                        <Card
                            variant="soft"
                            orientation="horizontal"
                            sx={{
                               // boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder',
                                "&:hover": { backgroundColor: "aliceblue", color: '#262673', boxShadow:'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' }
                            }}
                        >
                            <CardContent>
                                <Typography level="title-md" id="card-description" color='primary' textAlign={"center"} sx={{ fontSize: '0.8rem' }}>
                                    TOTAL TASKS / DAYS / HRS
                                </Typography>

                                <Typography textAlign={"center"} level="h2" id="card-description" marginTop={"24px"}>

                                    <Chip style={{ borderRadius: '5px', backgroundColor: 'orange', color: '#262673', fontSize: '0.8rem' }}
                                        label={<CountUp start={0} end={performanceData.totalTaskCount} duration={1} />}
                                    ></Chip>&nbsp;/&nbsp;<Chip style={{ borderRadius: '5px', backgroundColor: '#262673', color: 'white', fontSize: '0.8rem' }}
                                        label={<><CountUp start={0} end={performanceData.numberOfDaysWorkingInOffice} duration={1} />&nbsp;Days </>}
                                    ></Chip>&nbsp;/&nbsp;<Chip style={{ borderRadius: '5px', backgroundColor: '#262673', color: 'white', fontSize: '0.8rem' }}
                                        label={<><CountUp start={0} end={Math.floor(performanceData.overAllMinsInOffice / 60)} duration={1} />{"."}{(performanceData.overAllMinsInOffice % 60) < 10 ? `0` + (performanceData.overAllMinsInOffice % 60) : (performanceData.overAllMinsInOffice % 60)}&nbsp;Hrs </>}
                                    ></Chip>


                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item md={1}>
                        <Card
                            variant="soft"
                            orientation="horizontal"
                            sx={{
                                "&:hover": { backgroundColor: "aliceblue", color: '#262673', boxShadow:'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' }
                               // boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder', paddingRight: '10px', paddingLeft: '10px'
                            }}
                        >
                            <CardContent>
                                <Typography textAlign={"center"} level="title-md" id="card-description" color='primary' sx={{ fontSize: '0.8rem' }}>
                                    REWORK
                                </Typography>
                                <Typography textAlign={"center"} level="h2" id="card-description" marginTop={"24px"}>
                                    <Chip color={performanceData.inprogressReworkCount > 0 ? 'error' : 'default'} label={<CountUp start={0} end={performanceData.inprogressReworkCount} duration={1} />}></Chip>
                                </Typography>


                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item md={4}>
                        <Card
                            variant="soft"
                            orientation="horizontal"
                            sx={{
                                "&:hover": { backgroundColor: "aliceblue", color: '#262673', boxShadow:'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' }
                               // boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder',
                            }}
                        >
                            <CardContent>
                                <Typography textAlign={"center"} level="title-md" id="card-description" color='primary' sx={{ fontSize: '0.8rem' }}>
                                    PRODUCTION HOURS
                                </Typography>
                                <Grid container textAlign={"center"}>
                                    <Grid item md={4} style={{ marginTop: '10px' }}>
                                        <Typography level="body-sm" id="card-description" sx={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
                                            ESTIMATED
                                        </Typography>
                                        <Typography level="body-xs" id="card-description">
                                            <Chip size="small" style={{ borderRadius: '5px', backgroundColor: '#262673', color: 'white', fontSize: '0.8rem', fontWeight: 'bold' }}
                                                label={<>{convertSecondsToHours(performanceData.inprogressEstimatedTime)}&nbsp;Hrs </>}>

                                            </Chip>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} style={{ marginTop: '10px' }}>
                                        <Typography level="body-sm" id="card-description" sx={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
                                            ACTUAL
                                        </Typography>
                                        <Typography level="body-xs" id="card-description">
                                            <Chip size="small" style={{ borderRadius: '5px', backgroundColor: '#262673', color: 'white', fontSize: '0.8rem', fontWeight: 'bold' }}
                                                label={<>{convertSecondsToHours(performanceData.actualInprogressTime)}&nbsp;Hrs </>}>

                                            </Chip>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        {/* <CircularProgress style={{ float: 'right' }} size="lg" determinate value={parseInt(performanceData.inprogressEfficiencyPercentage).toFixed(2) > 100 ? 100 : parseInt(performanceData.inprogressEfficiencyPercentage).toFixed(2)} color={parseInt(performanceData.inprogressEfficiencyPercentage).toFixed() > 90 ? "success" : parseInt(performanceData.inprogressEfficiencyPercentage).toFixed() > 70 ? "warning" : "danger"}>
                                                            <Typography>{parseInt(performanceData.inprogressEfficiencyPercentage).toFixed()}%</Typography>
                                                        </CircularProgress> */}



                                        <AnimatedProgressProvider
                                            valueStart={0}
                                            valueEnd={parseInt(performanceData.inprogressEfficiencyPercentage).toFixed()}
                                            duration={1}
                                            easingFunction={easeQuadInOut}
                                        >
                                            {value => {
                                                const roundedValue = Math.round(value);
                                                return (
                                                    <div style={{ width: 95 }}>
                                                        <CircularProgressbar
                                                            value={value}
                                                            text={`${roundedValue}%`}
                                                            strokeWidth={"12"}
                                                            styles={{
                                                                root: {
                                                                    height: '65px',
                                                                },
                                                                path: {
                                                                    stroke: (roundedValue > 90) ? "#a7a870" : (roundedValue > 60) ? "orange" : "#ed8a2f",
                                                                    strokeLinecap: 'round',
                                                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                                                    transformOrigin: 'center center',
                                                                },
                                                                trail: {
                                                                    stroke: '#F0F4F8',
                                                                    transformOrigin: 'center center',
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </AnimatedProgressProvider>
                                    </Grid>
                                </Grid>





                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item md={4}>
                        <Card
                            variant="soft"
                            orientation="horizontal"
                            sx={{
                                "&:hover": { backgroundColor: "aliceblue", color: '#262673', boxShadow:'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' }
                               // boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder',
                            }}
                        >
                            <CardContent>
                                <Typography textAlign={"center"} level="title-md" id="card-description" color='primary' sx={{ fontSize: '0.8rem' }}>
                                    QC HOURS
                                </Typography>

                                <Grid container textAlign={"center"}>
                                    <Grid item md={4} style={{ marginTop: '10px' }}>
                                        <Typography level="body-sm" id="card-description" sx={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
                                            ESTIMATED
                                        </Typography>
                                        <Typography level="body-xs" id="card-description">

                                            <Chip size="small" style={{ borderRadius: '5px', backgroundColor: '#262673', color: 'white', fontSize: '0.8rem', fontWeight: 'bold' }}
                                                label={<>{convertSecondsToHours(performanceData.qcEstimatedTime)}&nbsp;Hrs </>}>

                                            </Chip>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} style={{ marginTop: '10px' }}>
                                        <Typography level="body-sm" id="card-description" sx={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
                                            ACTUAL
                                        </Typography>
                                        <Typography level="body-xs" id="card-description">
                                            <Chip size="small" style={{ borderRadius: '5px', backgroundColor: '#262673', color: 'white', fontSize: '0.8rem', fontWeight: 'bold' }}
                                                label={<>{convertSecondsToHours(performanceData.actualQcTime)}&nbsp;Hrs </>}>

                                            </Chip>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        {/* <CircularProgress size="lg" style={{ float: 'right' }} determinate value={parseInt(performanceData.qcEfficiencyPercentage).toFixed(2)} color={parseInt(performanceData.qcEfficiencyPercentage).toFixed() > 90 ? "success" : parseInt(performanceData.qcEfficiencyPercentage).toFixed() > 70 ? "warning" : "danger"}>
                                                            <Typography>{parseInt(performanceData.qcEfficiencyPercentage).toFixed()}%</Typography>
                                                        </CircularProgress> */}



                                        <AnimatedProgressProvider
                                            valueStart={0}
                                            valueEnd={parseInt(performanceData.qcEfficiencyPercentage).toFixed()}
                                            duration={1}
                                            easingFunction={easeQuadInOut}
                                        >
                                            {value => {
                                                const roundedValue = Math.round(value);
                                                return (
                                                    <div style={{ width: 95 }}>
                                                        <CircularProgressbar
                                                            value={value}
                                                            text={`${roundedValue}%`}
                                                            strokeWidth={"12"}
                                                            styles={{
                                                                root: {
                                                                    height: '65px',
                                                                },
                                                                path: {
                                                                    stroke: (roundedValue > 90) ? "#a7a870" : (roundedValue > 60) ? "orange" : "#ed8a2f",
                                                                    strokeLinecap: 'round',
                                                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                                                    transformOrigin: 'center center',
                                                                },
                                                                trail: {
                                                                    stroke: '#F0F4F8',
                                                                    transformOrigin: 'center center',
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </AnimatedProgressProvider>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default ReportCard;
